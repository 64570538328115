import React from "react"
import { format } from "date-fns"
import { graphql, Link } from "gatsby"

import SEO from "../components/SEO"
import Layout from "../components/core/Layout"
import { Row, Container, Col } from "../components/core/Grid"
import { Excerpt, Wrap } from "../components/Blog/BlogStyles"

const BlogPage = ({ data }) => {
  const blogData = data.allWpBlog.nodes

  return (
    <>
      <SEO />
      <Layout>
        <Wrap>
          <Row p="75px 0" smP="25px 0" />
          <Container width="1200px">
            <h2 style={{ marginBottom: "8px" }}>
              Scratchpad
              <span style={{ fontSize: "3.5rem", lineHeight: "1" }}>:</span>
            </h2>
            <p style={{ fontSize: "24px" }}>
              The blog that nobody asked for nor wants.
            </p>
            <Row>
              <Col mdWidth="70%" m="0 5% 0 0">
                {blogData.map(blog => {
                  const date = new Date(blog.date)
                  const formattedDate = format(date, "MMM do, yyyy")
                  return (
                    <Excerpt>
                      <Col mdWidth="25%" p="16px">
                        <img src="http://placekitten.com/200/150" alt="" />
                      </Col>
                      <Col mdWidth="75%" p="16px">
                        <small style={{ marginBottom: "8px" }}>
                          {formattedDate}
                        </small>
                        <h4
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            margin: "8px 0",
                          }}
                          dangerouslySetInnerHTML={{ __html: blog.title }}
                        />

                        <p dangerouslySetInnerHTML={{ __html: blog.excerpt }} />
                        <Link to={`/blog/${blog.slug}`}>Read More</Link>
                      </Col>
                    </Excerpt>
                  )
                })}
              </Col>
              <Col mdWidth="25%">
                <h4>All Categories</h4>
                <hr />
              </Col>
            </Row>
          </Container>
        </Wrap>
      </Layout>
    </>
  )
}

export default BlogPage

export const query = graphql`
  {
    allWpBlog {
      nodes {
        title
        excerpt
        content
        slug
        date
      }
    }
  }
`
