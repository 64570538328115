import styled from "styled-components"
import { Row } from "../core/Grid"

export const Wrap = styled.div`
  padding: 16px;
  min-height: calc(100vh - 188px);
`

export const Excerpt = styled(Row)`
  border-top: 1px solid;
  padding: 16px 0;

  &:last-of-type {
    border-bottom: 1px solid;
  }

`